import { SVGProps } from 'react';

export const LockIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="47"
      height="66"
      viewBox="0 0 47 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.7 56.44C39.42 58.28 32.06 64.68 31.1 64.68C28.94 64.68 13.58 60.12 1.49998 48.12C1.09998 47.16 0.859981 23.96 1.49998 22.2C5.97998 29 33.02 41.4 35.02 41.08C37.9 40.68 45.82 31.56 45.82 31.56C45.98 32.92 42.38 52.28 40.7 56.44Z"
        fill="#AEDFEF"
      />
      <path
        d="M45.82 31.56C45.82 31.56 40.54 41.08 35.02 41.08C33.02 41.4 5.98 29 1.5 22.2C2.14 20.36 11.74 15.96 13.1 15.96C14.46 15.96 35.98 23.16 44.78 30.6C45.18 30.92 45.5 31.24 45.82 31.56Z"
        fill="#E1EBFD"
      />
      <path
        d="M20.14 18.1199C22.3 18.9199 24.78 19.8799 27.42 20.9199"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.57996 22.2C1.81996 21.56 3.01996 20.68 4.53996 19.8"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.7 56.4401C39.42 58.2801 32.06 64.6801 31.1 64.6801C31.1 64.6801 32.86 56.6801 35.02 41.0801C42.14 37.6401 45.82 31.5601 45.82 31.5601C45.98 32.9201 42.38 52.2801 40.7 56.4401Z"
        fill="#08080C"
      />
      <path
        d="M30.78 39.7201C23.98 36.9201 10.94 30.5201 4.62 25.3201"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.1 41.0801C42.94 36.6801 45.9 31.5601 45.9 31.5601"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.82 31.56C45.98 32.92 42.38 52.28 40.7 56.44C39.42 58.28 32.06 64.68 31.1 64.68C28.94 64.68 13.58 60.12 1.49998 48.12C1.09998 47.16 0.859981 23.96 1.49998 22.2"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1 64.7599C31.1 64.7599 32.86 56.7599 35.02 41.1599"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.18 31.72C37.98 35.72 29.74 34.04 29.98 31.56C30.22 28.52 31.02 24.92 30.7 20.28C30.38 15.64 27.18 10.28 23.98 9.24002C15.18 6.44002 15.74 19.16 15.58 21.16C15.58 22.2 13.74 23 12.06 22.92C10.54 22.76 9.09997 22.28 8.77997 20.84C8.77997 11.4 10.7 -2.43998 26.78 2.12002C44.22 7.00002 39.1 31.64 39.1 31.64L39.18 31.72Z"
        fill="white"
      />
      <path
        d="M31.58 8.67993C31.58 8.67993 34.7 12.1999 35.18 16.7599"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.74 12.7599C14.38 9.79994 16.46 6.59994 20.3 6.67994"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.06 30.36C30.3 27.56 31.02 24.04 30.7 20.2C30.3 14.28 27.18 10.2 23.98 9.16002C15.18 6.36002 15.74 19.08 15.58 21.08"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.62 33.8802C35.34 34.2002 33.82 34.0402 32.62 33.7202"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.85999 20.9201C8.85999 11.4801 10.78 -2.3599 26.86 2.2001C44.3 7.0801 39.18 31.7201 39.18 31.7201"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 22.8402C13.1 22.9202 12.62 23.0002 12.14 22.9202C11.66 22.9202 11.18 22.8402 10.78 22.6802"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
