'use client';

import * as AvatarPrimitive from '@radix-ui/react-avatar';
import * as React from 'react';

import { cn } from '@/lib/utils';

export const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn('relative flex h-10 w-10 shrink-0 rounded-full', className)}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

export const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn(
      'aspect-square rounded-full overflow-hidden h-full w-full',
      className,
    )}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

export interface AvatarFallbackProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> {
  children?: string;
}

export const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  AvatarFallbackProps
>(({ children, className, ...props }, ref) => {
  const childrenParts = children?.split(' ') || [];
  const fallbackContent = childrenParts
    .map((part) => part.charAt(0).toUpperCase())
    .join('')
    .slice(0, 2);

  return (
    <AvatarPrimitive.Fallback
      ref={ref}
      className={cn(
        'flex h-full w-full items-center justify-center rounded-full bg-background-inset-default text-content-subtext-default',
        className,
      )}
      {...props}
    >
      {fallbackContent}
    </AvatarPrimitive.Fallback>
  );
});
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export interface AvatarBadgeProps
  extends React.ComponentPropsWithoutRef<'span'> {
  disableBorder?: boolean;
  size?: 'small' | 'medium' | 'large';
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export const AvatarBadge = React.forwardRef<
  React.ElementRef<'span'>,
  AvatarBadgeProps
>(
  (
    {
      className,
      size = 'medium',
      disableBorder = false,
      position = 'bottom-right',
      ...props
    },
    ref,
  ) => (
    <span
      ref={ref}
      className={cn(
        'absolute rounded-full flex items-center justify-center text-content-subtext-default [&>*]:rounded-full [&>*]:h-full [&>*]:w-full',
        {
          '-top-0.5 -left-0.5': position === 'top-left',
          '-top-0.5 -right-0.5': position === 'top-right',
          '-bottom-0.5 -left-0.5': position === 'bottom-left',
          '-bottom-0.5 -right-0.5': position === 'bottom-right',
          'h-3 w-3 ': size === 'small',
          'h-4 w-4': size === 'medium',
          'h-5 w-5': size === 'large',
          'border border-white': size == 'small' && !disableBorder,
          'border-2 border-white': size == 'medium' && !disableBorder,
          'border-[3px] border-white': size == 'large' && !disableBorder,
        },
        className,
      )}
      {...props}
    />
  ),
);
AvatarBadge.displayName = 'AvatarBadge';

export interface AvatarIconProps
  extends React.ComponentPropsWithoutRef<'span'> {
  size?: 'small' | 'medium' | 'large';
}

export const AvatarIcon = React.forwardRef<
  React.ElementRef<'span'>,
  AvatarIconProps
>(({ className, size = 'medium', ...props }, ref) => (
  <span
    ref={ref}
    className={cn(
      'flex h-full w-full items-center justify-center rounded-full bg-background-inset-default text-content-subtext-default',
      {
        '[&>svg]:h-4 [&>svg]:w-4': size === 'small',
        '[&>svg]:h-5 [&>svg]:w-5': size === 'medium',
        '[&>svg]:h-6 [&>svg]:w-6': size === 'large',
      },
      className,
    )}
    {...props}
  />
));
AvatarIcon.displayName = 'AvatarIcon';
