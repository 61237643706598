'use client';

import { startCase } from 'lodash';
import Link from 'next/link';

import { Avatar, AvatarFallback, AvatarImage } from '@/ui/components/avatar';
import { Center, Column, Row } from '@/ui/components/flex';
import { OTPInput } from '@/ui/components/otp-input';
import { PageSubtitle, PageTitle } from '@/ui/components/page';
import { Text } from '@/ui/components/text';
import { LockIllustration } from '@/ui/svgs/lock-illustration';

import { useRouter } from '@/lib/navigation';
import { cn } from '@/lib/utils';

import { Button } from '../button';

export interface PasscodeVerificationFormProps {
  title: string;
  subtitle?: string;
  isKnownUser: boolean;
  passcodeLength: number;
  onChange: (value: string) => void;
  onResend?: () => void;
  recoverPasscodeLink?: string;
  user?: {
    name: string;
    profileImageUrl?: string;
  };
  cancelUrl?: string;
}

export function PasscodeVerificationForm({
  title,
  subtitle,
  isKnownUser,
  passcodeLength,
  recoverPasscodeLink,
  user: { name, profileImageUrl } = { name: '', profileImageUrl: '' },
  onChange,
  onResend,
  cancelUrl,
}: Readonly<PasscodeVerificationFormProps>) {
  const router = useRouter();

  const handleRecoverPasscodeNavigation = () =>
    recoverPasscodeLink && router.push(recoverPasscodeLink);

  return (
    <Column
      className={cn('gap-[88px]', {
        'md:gap-[128px]': !isKnownUser,
        'md:gap-[88px]': isKnownUser,
      })}
    >
      <Column align="center" className="gap-units-unit32 lg:gap-units-unit56">
        <Column align="center" className="gap-units-unit24 md:gap-units-unit32">
          {isKnownUser ? (
            <Avatar className="h-units-unit64 w-units-unit64">
              <AvatarImage
                src={profileImageUrl}
                role="img"
                aria-label="profile-image"
                alt="profile picture"
              />
              <AvatarFallback className="font-medium typography-header-default">
                {startCase(name)}
              </AvatarFallback>
            </Avatar>
          ) : (
            <Center className="h-units-unit80 w-units-unit80">
              <LockIllustration aria-label="Lock Illustration" role="image" />
            </Center>
          )}
          <Center
            direction="column"
            className="gap-units-unit20 md:gap-units-unit24"
          >
            <PageTitle
              title={title}
              subtitle={<PageSubtitle>{subtitle}</PageSubtitle>}
            />
          </Center>
        </Column>

        <OTPInput
          disabled={false}
          length={passcodeLength}
          onChange={onChange}
          onResend={onResend}
        />
      </Column>
      {recoverPasscodeLink && (
        <Button
          variant="link"
          appearance="plain"
          onClick={handleRecoverPasscodeNavigation}
          aria-label="recover-passcode-link"
          className={{
            root: 'text-center typography-body-default no-underline font-medium text-primary-l7',
          }}
        >
          I can’t remember my passcode
        </Button>
      )}
      {isKnownUser && cancelUrl && (
        <Row
          justify="center"
          align="center"
          gapX="units-unit6"
          className="text-center absolute w-full left-0 right-0 bottom-units-unit48 "
        >
          <Text className="typography-body-default">Not you?</Text>
          <Text
            as={Link}
            href={cancelUrl}
            aria-label="cancel-link"
            className="typography-body-default text-center font-medium text-primary-l7"
          >
            Log in with a different account
          </Text>
        </Row>
      )}
    </Column>
  );
}
