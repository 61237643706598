import { createComponent } from '@/ui/system';
import { Flex, FlexProps } from './flex';

export type ColumnProps = Omit<FlexProps, 'direction'>;

export const Column = createComponent<ColumnProps>((props, ref) => {
  return <Flex {...props} ref={ref} direction='column' />;
});

Column.displayName = 'Column';
