import { classes, defineVariants } from '@/ui/system';

export type FlexVariants = typeof flexVariants;

export const flexVariants = defineVariants({
  base: 'flex',
  variants: {
    inline: {
      true: 'inline-flex',
    },
    direction: {
      column: 'flex-col',
      row: 'flex-row',
    },
    align: {
      start: 'items-start',
      end: 'items-end',
      baseline: 'items-baseline',
      stretch: 'items-stretch',
      center: 'items-center',
    },
    alignSelf: {
      start: 'self-start',
      end: 'self-end',
    },
    justify: {
      normal: 'justify-normal',
      start: 'justify-start',
      end: 'justify-end',
      center: 'justify-center',
      between: 'justify-between',
      around: 'justify-around',
      evenly: 'justify-evenly',
      stretch: 'justify-stretch',
    },
    flex: {
      none: 'flex-none',
      auto: 'flex-auto',
      1: 'flex-1',
    },
    gap: classes.gap,
    gapX: classes.gapX,
    gapY: classes.gapY,
    wrap: {
      true: 'flex-wrap',
    },
    noShrink: {
      true: 'shrink-0',
    },
    grow: {
      true: 'grow',
    },
    reversed: {
      true: '',
    },
  },
  compoundVariants: [
    {
      direction: 'column',
      reversed: true,
      class: 'flex-col-reverse',
    },
    {
      direction: 'row',
      reversed: true,
      class: 'flex-row-reverse',
    },
  ],
});
