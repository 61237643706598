import { createComponent } from '@/ui/system';
import { Flex, FlexProps } from './flex';

export type RowProps = Omit<FlexProps, 'direction'>;

export const Row = createComponent<RowProps>((props, ref) => {
  return <Flex {...props} ref={ref} direction="row" />;
});

Row.displayName = 'Row';
