import { flexVariants, type FlexVariants } from './flex.cx';
import {
  createComponent,
  HTMLCustomVariantsAwareProps,
  useTailwindVariants,
} from '@/ui/system';

export interface FlexProps
  extends HTMLCustomVariantsAwareProps<'div', FlexVariants> {}

export const Flex = createComponent<FlexProps>(
  ({ as: Component = 'div', ...props }, ref) => {
    const blueprint = useTailwindVariants(props, flexVariants);

    return <Component ref={ref} {...blueprint.ownProps} />;
  },
);

Flex.displayName = 'Flex';
