'use client';

import { useState } from 'react';

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/ui/components/input-otp';

import { Column } from '../flex';
import { ResendCode } from './resend-code';

interface OTPInputProps {
  length: number;
  disabled?: boolean;
  onChange: (value: string) => void;
  onResend?: () => void;
  enableResendInSeconds?: number;
}

export function OTPInput({
  length,
  onChange,
  onResend,
  disabled = false,
  enableResendInSeconds,
  ...props
}: Readonly<OTPInputProps>) {
  const [value, setValue] = useState('');

  const handleChange = async (newValue: string) => {
    if (newValue.length > length) return;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Column align="center" className="gap-y-[120px] md:gap-y-[128px]">
      <InputOTP
        autoFocus
        maxLength={length}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        {...props}
      >
        <InputOTPGroup>
          {Array.from({ length }).map((_, index) => (
            <InputOTPSlot key={index} index={index} />
          ))}
        </InputOTPGroup>
      </InputOTP>
      {onResend && <ResendCode {...{ onResend, enableResendInSeconds }} />}
    </Column>
  );
}
