import { NewTransactionSearchParams } from '@/features/transaction/shared/hooks';

import {
  DashboardMessagingAlertType,
  DashboardMessagingDialogType,
} from './types';

export const ROUTES = {
  SIGN_IN: {
    INDEX: '/signin',
    get $INDEX() {
      return (redirect: string, fallback: string) =>
        `${this.INDEX}${redirect ? '?' + new URLSearchParams({ redirect, fallback }).toString() : ''}`;
    },
    PASSCODE_VERIFICATION: '/signin/confirm-passcode',
    get $PASSCODE_VERIFICATION() {
      return (fallback: string) =>
        `${this.PASSCODE_VERIFICATION}?fallback=${encodeURIComponent(fallback)}`;
    },
  },
  get $SIGN_IN() {
    return (redirect?: string): string =>
      `${this.SIGN_IN.INDEX}?redirect=${redirect}`;
  },
  TWO_FA: {
    INDEX: '/2fa',
    get $INDEX() {
      return (redirect: string, fallback: string) =>
        `${this.INDEX}${redirect ? '?' + new URLSearchParams({ redirect, fallback }).toString() : ''}`;
    },
    PASSCODE: '/2fa/passcode',
    FACE_MATCH: '/2fa/face_match',
    OTP: '/2fa/otp',
  },
  PASSCODE_RECOVERY: {
    INDEX: '/recover-passcode',
    CREATE_PASSCODE: '/recover-passcode/create',
    CONFIRM_PASSCODE: '/recover-passcode/confirm',
    get $INDEX() {
      return (redirect: string, fallback: string): string =>
        `${this.INDEX}${redirect ? '?' + new URLSearchParams({ redirect, fallback }).toString() : ''}`;
    },
  },
  ACCOUNTS: {
    INDEX: '/accounts',
    ADD_MONEY: '/add',
    get $ADD_MONEY() {
      return (accountId: string) =>
        `${this.INDEX}/${accountId}${this.ADD_MONEY}`;
    },
  },
  SIGN_UP: {
    INDEX: '/signup',
    get $INDEX() {
      return (inviteCode: string) => `${this.INDEX}?inviteCode=${inviteCode}`;
    },
    INVITEE: {
      INDEX: '/j',
      $CODE: (inviteCode: string) => `/j/${inviteCode}`,
    },
    WAITLIST: '/signup/waitlist',
    QUOTA_REACHED: '/signup/quota-reached',
    get $QUOTA_REACHED() {
      return (inviteCode: string) =>
        `${this.QUOTA_REACHED}?inviteCode=${inviteCode}`;
    },
    PHONE_VERIFICATION: '/signup/phone-verification',
    PASSCODE: {
      CREATE: '/signup/passcode/create',
      CONFIRM: '/signup/passcode/confirm',
      CONFIRMED: '/signup/passcode/confirmed',
    },
  },
  DASHBOARD: {
    INDEX: '/dashboard',
    ACCOUNT_DETAILS: '/dashboard/account-details',
    get $INDEX() {
      return (searchParams: {
        alert?: DashboardMessagingAlertType;
        dialog?: DashboardMessagingDialogType;
      }) => {
        const queryParams = new URLSearchParams(searchParams);
        return this.INDEX + (queryParams ? '?' + queryParams.toString() : '');
      };
    },
  },
  EARN: '/earn',
  INVITE: '/invite',
  PHONE_VERIFICATION: '/secondary-contact-verification/phone',
  EMAIL_VERIFICATION: '/secondary-contact-verification/email',
  ONBOARDING: {
    PERSONAL_INFORMATION: '/personal-information',
    ADDRESS_INFORMATION: '/address-information',
    KYC: {
      START_KYC: '/kyc/upgrade/start',
      get $START_KYC() {
        return (source: string) =>
          `${this.START_KYC}?${new URLSearchParams({ source }).toString()}`;
      },
      UPGRADE: '/kyc/upgrade/id-verification',
      STATUS: '/kyc/upgrade/status',
      LIVELINESS: '/kyc/liveliness',
      ID_VERIFICATION: '/kyc/id-verification',
      SUCCESS: '/kyc/success',
    },
  },
  TRANSACTIONS: {
    INDEX: '/transactions',
    DETAILS: '/transactions/details',
    get $DETAILS() {
      return (id: string) => `${this.DETAILS}/${id}`;
    },
    NEW: {
      INDEX: '/transactions/new',
      get $INDEX() {
        return (params: NewTransactionSearchParams) => {
          const stringParams = Object.fromEntries(
            Object.entries(params).map(([key, value]) => [key, String(value)]),
          ) as Record<string, string>;

          return `${this.INDEX}?${new URLSearchParams(stringParams).toString()}`;
        };
      },
      RECIPIENT: '/transactions/new/recipient',
      get $RECIPIENT() {
        return (params: NewTransactionSearchParams) => {
          const stringParams = Object.fromEntries(
            Object.entries(params).map(([key, value]) => [key, String(value)]),
          ) as Record<string, string>;

          return `${this.RECIPIENT}?${new URLSearchParams(stringParams).toString()}`;
        };
      },
      PAYMENT_METHOD: '/transactions/new/payment-method',
      REVIEW: '/transactions/new/review',
      PROCESSING: '/transactions/new/processing',
    },
  },
  RECIPIENTS: {
    INDEX: '/recipients',
    ADD_RECIPIENT: '/recipients/new',
    EDIT_RECIPIENT: '/recipients/edit-recipient',
    /* get $EDIT_RECIPIENT() {
      return (id: string) => `${this.EDIT_RECIPIENT}/${id}`;
    }, */
    DETAILS: '/recipients/details',
    get $DETAILS() {
      return (id: string) => `${this.DETAILS}/${id}`;
    },
  },
  SUPPORT: '/support',
  SETTINGS: {
    INDEX: '/settings',
    PROFILE: '/settings/profile',
    TRANSFER_LIMITS: '/settings/transfer-limits',
    SECURITY: '/settings/security',
    NOTIFICATIONS: '/settings/notifications',
    get $INDEX() {
      return (source: string) =>
        `${this.INDEX}?${new URLSearchParams({ source }).toString()}`;
    },
    get $PROFILE() {
      return (source: string) =>
        `${this.PROFILE}?${new URLSearchParams({ source }).toString()}`;
    },
  },
  LOGOUT: '/signout',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
} as const;
