import { createComponent } from '@/ui/system';
import { Flex, FlexProps } from './flex';

export type CenterProps = Omit<FlexProps, 'align' | 'justify'>;

export const Center = createComponent<CenterProps>((props, ref) => {
  return <Flex {...props} ref={ref} align="center" justify="center" />;
});

Center.displayName = 'Center';
